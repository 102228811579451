import { schemaBuilder } from "lib/validation";
import {
  ProcessTemplateArtifact,
  ProcessTemplateArtifactValidationSchema,
  ProcessTemplateArtifactSchemaType,
} from "lib/schemas";
import { WritableModel } from "lib/schemas/abstract";

export enum AIProviders {
  openai = "OpenAI",
}

export interface AITaskInstruction extends ProcessTemplateArtifact, WritableModel<true> {
  name: string;
  prompt: string;
  input?: string;
  model_identifier?: string;
  provider_code: keyof typeof AIProviders;
  max_tokens?: number;
  readonly created_at?: Date;
  readonly updated_at?: Date;
}

type WriteOnlyAITaskInstruction = Omit<AITaskInstruction, "id" | "created_at" | "updated_at">;

export const AITaskInstructionValidationSchema: ProcessTemplateArtifactSchemaType<WriteOnlyAITaskInstruction> =
  ProcessTemplateArtifactValidationSchema.shape({
    name: schemaBuilder.string().required("You must provide a name for the instructions."),
    prompt: schemaBuilder.string().required("You must provide instructions."),
    input: schemaBuilder.string().nullable().optional(),
    max_tokens: schemaBuilder.number().nullable().optional(),
    model_identifier: schemaBuilder.string().nullable(),
    provider_code: schemaBuilder.mixed().oneOf(Object.keys(AIProviders)).optional(),
  });
