import Slider, { SliderProps } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { COLORSCHEME } from "theme/styles";

interface CustomSliderProps extends SliderProps {
  sliderColor?: React.CSSProperties["color"];
}

const CustomSliderBoxShadow = "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

export const CustomSlider = styled(Slider)<CustomSliderProps>(({ theme, sliderColor }) => ({
  color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
  height: 2,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: sliderColor ?? "#fff",
    boxShadow: CustomSliderBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: CustomSliderBoxShadow,
      },
    },
  },
  "& .MuiSlider-track": {
    border: "none",
    backgroundColor: sliderColor ?? COLORSCHEME.primary,
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: sliderColor ?? COLORSCHEME.primary,
      height: 10,
      width: 2,
    },
  },
}));

export const CustomSlider2 = styled(Slider)<CustomSliderProps>(({ theme, sliderColor }) => ({
  color: sliderColor ?? COLORSCHEME.primary,
  height: 3,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    border: `2px solid ${sliderColor ?? COLORSCHEME.primary}`,
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 30,
    height: 30,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: sliderColor ?? COLORSCHEME.primary,
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
  "& .MuiSlider-mark": {
    opacity: 1,
    backgroundColor: sliderColor ?? COLORSCHEME.primary,
    height: 10,
    width: 2,
  },
}));
