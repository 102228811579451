import {
  APIMany2OneSelectFormInput,
  ConcreteAPIMany2OneSelectFormInputProps,
  APIMany2OneEditButton,
} from "ui-widgets/forms";
import { IntegrationCredentialStore } from "lib/schemas";
import { APISERVICEPATHS } from "lib/api";
import { SecurityIcon } from "theme/icons";
import { COLORSCHEME } from "theme/styles";
import { useOSFormContext } from "ui-widgets/forms/providers";
import { StyledOSDialog, useToggleState, FormSubmitBubblingStopper } from "ui-widgets";
import { CredentialStoreForm } from "./CredentialStoreForm";

type IntegrationCredentialStoreInputProps<PrimaryObject> = ConcreteAPIMany2OneSelectFormInputProps<
  PrimaryObject,
  IntegrationCredentialStore
> &
  Pick<React.ComponentProps<typeof CredentialStoreForm>, "newCredentialStoreName">;

const credentialStoreIcon = <SecurityIcon fill={COLORSCHEME.primary} width={30} height={30} />;

export const CredentialStoreInput = <PrimaryObject,>({
  newCredentialStoreName,
  ...props
}: IntegrationCredentialStoreInputProps<PrimaryObject>) => {
  const { watch } = useOSFormContext<PrimaryObject>();

  const credentialStoreId = watch<any>(props.schema_field_key) as IntegrationCredentialStore["id"];

  const { handleClose: handleCloseEdit, handleOpen: handleOpenEdit, open: editIsOpen } = useToggleState();

  return (
    <>
      <APIMany2OneSelectFormInput<PrimaryObject, IntegrationCredentialStore>
        label={props.label ?? "Credential Store"}
        itemIdProperty="id"
        getOptionLabel={(option) => option.name}
        loadingText="Loading Credential Stores"
        apiServicePath={APISERVICEPATHS.integrationCredentialStores}
        inputStartAdornment={credentialStoreIcon}
        inputEndAdornment={<APIMany2OneEditButton objectId={credentialStoreId} onClick={handleOpenEdit} />}
        renderRelatedObjectForm={({ closeNewFormFn, newFormOpenState, updatePrimaryObject }) => (
          <CredentialStoreFormPopup
            open={newFormOpenState}
            onClose={closeNewFormFn}
            newCredentialStoreName={newCredentialStoreName}
            onSaveCredentialStore={(credentialStore) => {
              updatePrimaryObject(credentialStore.id, false);
              closeNewFormFn();
            }}
          />
        )}
        {...props}
      />
      {credentialStoreId && (
        <CredentialStoreFormPopup open={editIsOpen} onClose={handleCloseEdit} credentialStoreId={credentialStoreId} />
      )}
    </>
  );
};

type CredentialStoreFormPopupProps = React.ComponentProps<typeof CredentialStoreForm> &
  Pick<React.ComponentProps<typeof StyledOSDialog>, "open" | "onClose">;

export const CredentialStoreFormPopup = ({ open, onClose, ...formProps }: CredentialStoreFormPopupProps) => (
  <StyledOSDialog open={open} onClose={onClose} popUpIcon={credentialStoreIcon} popUpTitle="Credential Store">
    <FormSubmitBubblingStopper>
      <CredentialStoreForm {...formProps} />
    </FormSubmitBubblingStopper>
  </StyledOSDialog>
);
