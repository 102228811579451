import { TextField } from "@mui/material";
import { useOSFormContext } from "ui-widgets/forms/providers";
import { TextFormInputProps } from "ui-widgets/forms/input-fields/TextFormInput";

export const JSONFormInput = <T,>(props: TextFormInputProps<T>) => {
  const { setValue, watch } = useOSFormContext<T>();

  const onChange = (event) => {
    let parsedValue: any;
    try {
      parsedValue = JSON.parse(event.target.value);
    } catch (error) {
      parsedValue = event.target.value;
    }
    setValue(props.schema_field_key as any, parsedValue);
  };

  let displayValue = watch(props.schema_field_key as any) as any;
  const valueIsJSON = typeof displayValue === "object" || Array.isArray(displayValue);
  if (valueIsJSON) displayValue = JSON.stringify(displayValue, null, 2);

  return (
    <TextField
      {...props}
      multiline
      rows={props.rows ?? 8}
      value={displayValue}
      onChange={onChange}
      sx={{ background: "whitesmoke" }}
      inputProps={{ style: { color: `${valueIsJSON ? "green" : "red"}` } }}
    />
  );
};
