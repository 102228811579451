import React from "react";
import Stack from "@mui/material/Stack";
import { useGlobalScope } from "core/providers";
import { IntegrationCredentialStore } from "lib/schemas";
import { getDefaultCredentialStore } from "lib/factories";
import { useIntegrationsAPI } from "service-builder/services/IntegrationsAPI";
import { SecurityIcon } from "theme/icons";
import { COLORSCHEME } from "theme/styles";
import { OSActionButton, OSDialog } from "ui-widgets";
import { TextFormInput, JSONFormInput, OSAPIForm } from "ui-widgets/forms";

export interface CredentialStoreFormProps extends Pick<React.ComponentProps<typeof OSAPIForm>, "onFormCancel"> {
  newCredentialStoreName?: string;
  credentialStoreId?: IntegrationCredentialStore["id"];
  onSaveCredentialStore?: (credentialStore: IntegrationCredentialStore) => void;
}

export const CredentialStoreForm = ({
  newCredentialStoreName,
  credentialStoreId,
  onSaveCredentialStore,
  ...props
}: CredentialStoreFormProps) => {
  const {
    globalState: { selectedOrganization },
  } = useGlobalScope();

  const defaultNewCredentialStore = getDefaultCredentialStore({
    defaultName: newCredentialStoreName ?? `${selectedOrganization.name} credentials`,
    organization: selectedOrganization,
  });

  const { useCredentialStoreAPIForm } = useIntegrationsAPI();
  const useAPIFormReturn = useCredentialStoreAPIForm({
    credentialStoreDefaultValues: defaultNewCredentialStore,
    credentialStoreFetchId: credentialStoreId,
    updateCredentialStoreFromAPICallback: (credentialStore) => onSaveCredentialStore?.(credentialStore),
  });

  return (
    <OSAPIForm useAPIFormReturn={useAPIFormReturn} {...props}>
      <TextFormInput<IntegrationCredentialStore> schema_field_key="name" label="Name" />
      <Stack direction="row" spacing={2}>
        <JSONFormInput<IntegrationCredentialStore> schema_field_key="credentials" label="Credentials" />
        {credentialStoreId ? (
          <div>
            <DecryptedCredentials credentialStoreId={credentialStoreId} />
          </div>
        ) : null}
      </Stack>
    </OSAPIForm>
  );
};

const DecryptedCredentials = ({ credentialStoreId }) => {
  const [decryptedCredentials, setDecryptedCredentials] = React.useState<any>();
  const { useDecryptCredentials } = useIntegrationsAPI();

  const { decryptCredentials } = useDecryptCredentials();

  return (
    <OSDialog
      renderOpenButton={({ handleOpen }) => (
        <OSActionButton
          actionStyle="custom"
          customIcon={<SecurityIcon width={20} height={20} fill={COLORSCHEME.primary} />}
          size="large"
          onClick={() => {
            handleOpen();
            decryptCredentials(credentialStoreId, setDecryptedCredentials);
          }}
        >
          Decrypt
        </OSActionButton>
      )}
    >
      <pre>{JSON.stringify(decryptedCredentials, null, 2)}</pre>
    </OSDialog>
  );
};
